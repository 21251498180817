import { createUseStyles, useTheme } from "react-jss";
import classNames from "classnames";

import twitter from "assets/images/twitter-white.png";
import telegram from "assets/images/telegram-white.png"
import dex from "assets/images/dex-white.png";

const useStyles = createUseStyles((Themes) => {
  return {
    icon: {
      width: "50px",
      height: "50px",
      margin: "0 1rem",
    },
  };
});
function Links(props) {
  const theme = useTheme();
  const classes = useStyles({ props, theme });

  return (
    <div className="newsletter">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <em>Follow us on</em>
        </div>
        <div className="row d-flex align-items-center justify-content-center mt-px40 text-center">
          <div
          
            className={classes.icon}>
            <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/CHOP_CITY_"
          >
            <img src={twitter} alt="twitter link" />
            </a>
          </div>
          <div className={classes.icon}>
            <a
              href="https://t.me/ChopCityERC"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={telegram} alt="telegram link" />
            </a>
          </div>
          <div
            className={classNames(
              classes.icon,
              "row d-flex align-items-center justify-content-center"
            )}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.dextools.io/app/ether/pair-explorer/0x0697e5f9df6efc92e74c9a891cc5380a39c93a8b"
          >
            <img src={dex} alt="dextools link" />
          </a>
          </div>

        </div>
        <br></br>
        <div className="row d-flex align-items-center justify-content-center">
          <h3>$CHOP Contract: 0x9551672d604eb4f871b3c4260205107fcfc91e9e </h3>
        </div>
          
      </div>
    </div>
  );
}

export default Links;
