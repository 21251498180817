import { createUseStyles, useTheme } from "react-jss";
// import classNames from "classnames";

// Components
import Header from "Components/HomePageComponents/Header";
import Token from "Components/HomePageComponents/Token";
import Mint from "Components/HomePageComponents/Mint";
import Links from "Components/HomePageComponents/Links";


const useStyles = createUseStyles((Themes) => {
  return {};
});

function HomePage(props) {
  const theme = useTheme();
  const classes = useStyles({ props, theme });

  return (
    <div className={{}}>
      <main>
        <Header />

        <div className="gradient">
          <Token />
          <Mint amount={0}/>
        </div>
        <Links />
      </main>
    </div>
  );
}

export default HomePage;
