import mintSVG from "assets/images/redgreen.png";

import imgSmirk from "assets/images/smirk.png";
import imgBeatUp from "assets/images/beatup.png";
import imgNormal from "assets/images/normal.png";
import imgScreaming from "assets/images/screaming.png";
import { useState } from "react";
import { BigNumber } from "ethers";
import { ethers } from "ethers";

const abi = [ {
  "inputs": [ { "internalType": "string", "name": "_uri", "type": "string" }, {
    "internalType": "string",
    "name": "_provenanceHash",
    "type": "string"
  } ], "stateMutability": "nonpayable", "type": "constructor"
}, { "inputs": [], "name": "ApprovalCallerNotOwnerNorApproved", "type": "error" }, {
  "inputs": [],
  "name": "ApprovalQueryForNonexistentToken",
  "type": "error"
}, { "inputs": [], "name": "BalanceQueryForZeroAddress", "type": "error" }, {
  "inputs": [],
  "name": "MintERC2309QuantityExceedsLimit",
  "type": "error"
}, { "inputs": [], "name": "MintToZeroAddress", "type": "error" }, {
  "inputs": [],
  "name": "MintZeroQuantity",
  "type": "error"
}, {
  "inputs": [ { "internalType": "address", "name": "operator", "type": "address" } ],
  "name": "OperatorNotAllowed",
  "type": "error"
}, { "inputs": [], "name": "OwnerQueryForNonexistentToken", "type": "error" }, {
  "inputs": [],
  "name": "OwnershipNotInitializedForExtraData",
  "type": "error"
}, { "inputs": [], "name": "TransferCallerNotOwnerNorApproved", "type": "error" }, {
  "inputs": [],
  "name": "TransferFromIncorrectOwner",
  "type": "error"
}, { "inputs": [], "name": "TransferToNonERC721ReceiverImplementer", "type": "error" }, {
  "inputs": [],
  "name": "TransferToZeroAddress",
  "type": "error"
}, { "inputs": [], "name": "URIQueryForNonexistentToken", "type": "error" }, {
  "anonymous": false,
  "inputs": [ { "indexed": true, "internalType": "address", "name": "owner", "type": "address" }, {
    "indexed": true,
    "internalType": "address",
    "name": "approved",
    "type": "address"
  }, { "indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256" } ],
  "name": "Approval",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [ { "indexed": true, "internalType": "address", "name": "owner", "type": "address" }, {
    "indexed": true,
    "internalType": "address",
    "name": "operator",
    "type": "address"
  }, { "indexed": false, "internalType": "bool", "name": "approved", "type": "bool" } ],
  "name": "ApprovalForAll",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [ {
    "indexed": true,
    "internalType": "uint256",
    "name": "fromTokenId",
    "type": "uint256"
  }, { "indexed": false, "internalType": "uint256", "name": "toTokenId", "type": "uint256" }, {
    "indexed": true,
    "internalType": "address",
    "name": "from",
    "type": "address"
  }, { "indexed": true, "internalType": "address", "name": "to", "type": "address" } ],
  "name": "ConsecutiveTransfer",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [ {
    "indexed": true,
    "internalType": "address",
    "name": "previousOwner",
    "type": "address"
  }, { "indexed": true, "internalType": "address", "name": "newOwner", "type": "address" } ],
  "name": "OwnershipTransferred",
  "type": "event"
}, {
  "anonymous": false,
  "inputs": [ { "indexed": true, "internalType": "address", "name": "from", "type": "address" }, {
    "indexed": true,
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, { "indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256" } ],
  "name": "Transfer",
  "type": "event"
}, {
  "inputs": [],
  "name": "MAX_PER_TX",
  "outputs": [ { "internalType": "uint128", "name": "", "type": "uint128" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "MAX_SUPPLY",
  "outputs": [ { "internalType": "uint128", "name": "", "type": "uint128" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "MINT_PRICE",
  "outputs": [ { "internalType": "uint256", "name": "", "type": "uint256" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "OLD_URI",
  "outputs": [ { "internalType": "string", "name": "", "type": "string" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "PROVENANCE_HASH",
  "outputs": [ { "internalType": "string", "name": "", "type": "string" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "REVEALED_URI",
  "outputs": [ { "internalType": "string", "name": "", "type": "string" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "UNREVEALED_URI",
  "outputs": [ { "internalType": "string", "name": "", "type": "string" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [ { "internalType": "address", "name": "to", "type": "address" }, {
    "internalType": "uint256",
    "name": "tokenId",
    "type": "uint256"
  } ], "name": "approve", "outputs": [], "stateMutability": "payable", "type": "function"
}, {
  "inputs": [],
  "name": "areOtherMarketplacesAllowed",
  "outputs": [ { "internalType": "bool", "name": "", "type": "bool" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [ { "internalType": "address", "name": "owner", "type": "address" } ],
  "name": "balanceOf",
  "outputs": [ { "internalType": "uint256", "name": "", "type": "uint256" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "blockDataForReveal",
  "outputs": [ { "internalType": "uint256", "name": "", "type": "uint256" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "flipMintStatus",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [],
  "name": "flipOtherMarketplacesBlockingState",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [ { "internalType": "uint256", "name": "tokenId", "type": "uint256" } ],
  "name": "getApproved",
  "outputs": [ { "internalType": "address", "name": "", "type": "address" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [ { "internalType": "address", "name": "owner", "type": "address" }, {
    "internalType": "address",
    "name": "operator",
    "type": "address"
  } ],
  "name": "isApprovedForAll",
  "outputs": [ { "internalType": "bool", "name": "", "type": "bool" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "mintStatus",
  "outputs": [ { "internalType": "bool", "name": "", "type": "bool" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [ { "internalType": "uint8", "name": "quantity", "type": "uint8" } ],
  "name": "mintWojak",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
}, {
  "inputs": [],
  "name": "name",
  "outputs": [ { "internalType": "string", "name": "", "type": "string" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "owner",
  "outputs": [ { "internalType": "address", "name": "", "type": "address" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [ { "internalType": "uint256", "name": "tokenId", "type": "uint256" } ],
  "name": "ownerOf",
  "outputs": [ { "internalType": "address", "name": "", "type": "address" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "renounceOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [ { "internalType": "string", "name": "_revealURI", "type": "string" } ],
  "name": "reveal",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [ { "internalType": "address", "name": "from", "type": "address" }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, { "internalType": "uint256", "name": "tokenId", "type": "uint256" } ],
  "name": "safeTransferFrom",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
}, {
  "inputs": [ { "internalType": "address", "name": "from", "type": "address" }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, { "internalType": "uint256", "name": "tokenId", "type": "uint256" }, {
    "internalType": "bytes",
    "name": "data",
    "type": "bytes"
  } ], "name": "safeTransferFrom", "outputs": [], "stateMutability": "payable", "type": "function"
}, {
  "inputs": [ { "internalType": "address", "name": "operator", "type": "address" }, {
    "internalType": "bool",
    "name": "approved",
    "type": "bool"
  } ], "name": "setApprovalForAll", "outputs": [], "stateMutability": "nonpayable", "type": "function"
}, {
  "inputs": [ { "internalType": "string", "name": "_uri", "type": "string" } ],
  "name": "setBaseURI",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "inputs": [ { "internalType": "bytes4", "name": "interfaceId", "type": "bytes4" } ],
  "name": "supportsInterface",
  "outputs": [ { "internalType": "bool", "name": "", "type": "bool" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "symbol",
  "outputs": [ { "internalType": "string", "name": "", "type": "string" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "tokenIdShift",
  "outputs": [ { "internalType": "uint256", "name": "", "type": "uint256" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [ { "internalType": "uint256", "name": "_tokenId", "type": "uint256" } ],
  "name": "tokenURI",
  "outputs": [ { "internalType": "string", "name": "", "type": "string" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [],
  "name": "totalSupply",
  "outputs": [ { "internalType": "uint256", "name": "", "type": "uint256" } ],
  "stateMutability": "view",
  "type": "function"
}, {
  "inputs": [ { "internalType": "address", "name": "from", "type": "address" }, {
    "internalType": "address",
    "name": "to",
    "type": "address"
  }, { "internalType": "uint256", "name": "tokenId", "type": "uint256" } ],
  "name": "transferFrom",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
}, {
  "inputs": [ { "internalType": "address", "name": "newOwner", "type": "address" } ],
  "name": "transferOwnership",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
}, { "inputs": [], "name": "withdraw", "outputs": [], "stateMutability": "nonpayable", "type": "function" } ];

const contractAddress = "0xf5021283c7915a841b05b7f35aa5e5f5f4dd2e17";

// goerly: "0x36611440dd13b2762a647a8e37fa7321eb280449";
// mainnet: "0xf5021283c7915a841b05b7f35aa5e5f5f4dd2e17";

function Mint(props) {
  const [ amount, setAmount ] = useState(3);
  const [ minted, setMinted ] = useState(0);
  const [ mintPrice, setMintPrice ] = useState(0);
  const [ mintLive, setMintLive ] = useState(false);

  const [ signer, setSigner ] = useState(null);
  const [ address, setAddress ] = useState('');

  async function connect() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    setSigner(provider.getSigner());
    setAddress(await provider.getSigner().getAddress());

    const contract = new ethers.Contract(contractAddress, abi, provider.getSigner());
    const mintStatus = await contract['mintStatus']();
    const totalSupply = await contract['totalSupply']();
    const mintPrice = await contract['MINT_PRICE']();

    setMintLive(mintStatus);
    setMinted(totalSupply.toNumber());
    setMintPrice(+ethers.utils.formatEther(mintPrice));
  }

  async function mint(amount, signer) {
    const contract = new ethers.Contract(contractAddress, abi, signer);
    const gasLimit = await contract.estimateGas['mintWojak'](amount, {value: ethers.utils.parseEther("" + (amount * mintPrice))});
    const newGasLimit = BigNumber.from("" + Math.floor(gasLimit.toNumber() * 1.2));
    await contract['mintWojak'](amount, {
      gasLimit: newGasLimit,
      value: ethers.utils.parseEther("" + (amount * mintPrice))
    });
    setMinted((+minted) + (+amount));
  }

  return (
    <section className="standard-section token">
      <div className="container">
        <div className="row d-flex align-items-center text-center text-md-left">
          <div className="col-md-5 offset-md-1 order-2 order-md-1">
            <h1>Who will win the battle of Chop City?</h1>
            <p>
              {mintPrice || 0.01}Ξ @ Nov 10th, 9pm UTC
            </p>
            <div className="info">
              <br/>
              <span className="text-white">
                100 hand drawn, original, reimaged and classic Wojak traits.
              </span>
              <br/>
              <br/>
              <span className="text-white">
                Who will win, bulls or bears? Red or green Wojaks?
              </span>
              <br/>
              <br/>
              <span className="text-white">
                Price Pool: <a className="prize-link" target="_blank" href="https://docs.google.com/spreadsheets/d/1TLjuYv8yTAgIj80Attz01EjXsiq9SfRtCxqsth7Xlws">view here</a>
              </span>
              <br/>
              <br/>
              {address ? <span className="text-white">
                Wallet: {address}
              </span> : ''}
            </div>
            {signer ? <div className="mint mint-box">
                <span className="mint-amount">Qty:&nbsp;&nbsp;&nbsp;</span><input className="mint-input" type="number" min={ 1 } max={ 10 } value={ amount }
                       onChange={ e => setAmount(e.target.value.replace(/\D/g, '')) }/>
                <button className="mint-button" disabled={ !mintLive }
                        onClick={ () => mint(amount,signer) }>MINT { amount } for { amount * mintPrice }Ξ
                </button>
              </div> : <div className="mint">
              <button className="connect-button"
                      onClick={ () => connect() }>CONNECT
              </button>
            </div>}
            {signer ? <span className="text-white">
                Minted: { minted }/3030, max 10 per tx. { mintLive ? '' : 'Mint not live yet.' }
              </span> : '' }
          </div>

          <div className="col-md-6 order-1">
            <img src={ mintSVG } alt="image"/>
          </div>

          <div className="col-md-2 order-0 order-md-2 offset-md-1">
            <img src={ imgSmirk } alt="image"/>
            <h3>Green Wojaks</h3>
            <span>1000 supply.<br/>REKd if ETH hits $800 first<br/>Share prize pool if ETH hits $2200 first.</span>
          </div>
          <div className="col-md-2 order-0 order-md-2  offset-md-1">
            <img src={ imgScreaming } alt="image"/>
            <h3>Pink Wojaks</h3>
            <span>1000 supply.<br/>LIQd if ETH hits $2200 first<br/>Share prize pool if ETH hits $800 first.</span>
          </div>
          <div className="col-md-2 order-0 order-md-2 offset-md-1">
            <img src={ imgNormal } alt="image"/>
            <h3>White Wojaks</h3>
            <span>1000 supply.<br/>Neutral Observers<br/>Dont get REKd or LIQd<br/>Dont share prize pool.</span>
          </div>
          <div className="col-md-2 order-0 order-md-2 offset-md-1">
            <img src={ imgBeatUp } alt="image"/>
            <h3>Gold Wojaks</h3>
            <span>30 supply.<br/>Gold Wojaks have already made it.<br/>Each one wins 1m $CHOP tokens on reveal.</span>
          </div>

        </div>
      </div>
    </section>
  );
}

export default Mint;
