// import { createUseStyles, useTheme } from "react-jss";
// import classNames from "classnames";

// Images
import tokenSVG from "assets/images/wojak-green.png";

// const useStyles = createUseStyles((Themes) => {
//   return {};
// });

function Token(props) {
  // const theme = useTheme();
  // const classes = useStyles({ props, theme });

  return (
    <section className="standard-section token">
      <div className="container">
        <div className="row d-flex align-items-center text-center text-md-left">
          <div className="col-md-6">
            <img src={tokenSVG} alt="image"/>
          </div>
          <div className="col-md-5  offset-md-1 order-2 order-md-1">
            <h1>CHOP &nbsp;Token</h1>
            
            <p>$CHOP is a hedge against CHOP CITY
              
            </p>
            <div className="info">
            <ul>
              <li>A community token powered by memes</li>
              <li>1B total supply</li>
              <li>30M burned</li>
              <li>Liquidity locked for 9 months</li>
              <li>0 tax</li>
              <li>Over $10M in volume</li>

            </ul>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Token;
